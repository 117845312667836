<div class="sidebar">
<ul style="padding: 0">
        <li id="politics" (click)="toggleSidebar('politics')" #toggles data-id="politics">
          <div class="sidebar_img sidebar_img_trans" id="hpol" [ngClass]="{'open_opacity': category=='Родот и политиката'}"></div>
          <div class="sidebar_img" id="pol"></div>
          <div class="parent" [ngClass]="{'open_color': category=='Родот и политиката'}">
          <div >Политика</div>
          </div>
          <ul id="child_politics" [ngClass]="{'open_sidebar': category=='Родот и политиката'|| id=='politics' && toggle==true }">
            <li class="child" [ngClass]="{'open_color': selectType=='истражувања' && category=='Родот и политиката'}">
            <a routerLink="/category/Родот и политиката/research-list" >Истражувања</a></li>
            <li class="child" [ngClass]="{'open_color': selectType=='график' && category=='Родот и политиката'}">
            <a routerLink="/category/Родот и политиката/graph-list" >Графици</a></li>
            <li class="child" [ngClass]="{'open_color': selectType=='инфографик' && category=='Родот и политиката'}">
            <a routerLink="/category/Родот и политиката/infographic-list">Инфографици</a></li>
          </ul>
        </li>
        <li id="family" (click)="toggleSidebar('family')" #toggles data-id="family">
          <div class="sidebar_img sidebar_img_trans" id="hfam" [ngClass]="{'open_opacity': category=='Родот и семејството'}"></div>
          <div class="sidebar_img" id="fam"></div>
          <div class="parent" [ngClass]="{'open_color': category=='Родот и семејството'}">
          <div>Семејство</div>
          </div>
          <ul id="child_family" [ngClass]="{'open_sidebar': category=='Родот и семејството'|| id=='family' && toggle==true }">
            <li class="child" [ngClass]="{'open_color': selectType=='истражувања'&& category=='Родот и семејството'}">
            <a routerLink="/category/Родот и семејството/research-list" >Истражувања</a></li>
            <li class="child" [ngClass]="{'open_color': selectType=='график' && category=='Родот и семејството'}">
            <a routerLink="/category/Родот и семејството/graph-list" >Графици</a></li>
            <li class="child" [ngClass]="{'open_color': selectType=='инфографик' && category=='Родот и семејството'}">
            <a routerLink="/category/Родот и семејството/infographic-list">Инфографици</a></li>
          </ul>
        </li>
        <li id="economy" (click)="toggleSidebar('economy')" #toggles data-id="economy">
          <div class="sidebar_img sidebar_img_trans" id="heco" [ngClass]="{'open_opacity': category=='Родот и економијата'}"></div>
          <div class="sidebar_img" id="eco"></div>
          <div class="parent">
          <div>Економија</div></div>
          <ul id="child_economy" [ngClass]="{'open_sidebar': category=='Родот и економијата'|| id=='economy' && toggle==true }">
            <li class="child" [ngClass]="{'open_color': selectType=='истражувања' && category=='Родот и економијата'}">
            <a routerLink="/category/Родот и економијата/research-list" >Истражувања</a></li>
            <li class="child" [ngClass]="{'open_color': selectType=='график' && category=='Родот и економијата'}">
            <a routerLink="/category/Родот и економијата/graph-list" >Графици</a></li>
            <li class="child" [ngClass]="{'open_color': selectType=='инфографик' && category=='Родот и економијата'}">
            <a routerLink="/category/Родот и економијата/infographic-list">Инфографици</a></li>
          </ul>
        </li>
        <li id="youth" (click)="toggleSidebar('youth')" #toggles data-id="youth">
          <div class="sidebar_img sidebar_img_trans" id="hyou" [ngClass]="{'open_opacity': category=='Родот и младите'}"></div>
          <div class="sidebar_img" id="you"></div>
          <div class="parent">
          <div>Млади</div></div>
          <ul id="child_youth" [ngClass]="{'open_sidebar': category=='Родот и младите'|| id=='youth' && toggle==true }">
            <li class="child" [ngClass]="{'open_color': selectType=='истражувања' && category=='Родот и младите'}">
            <a routerLink="/category/Родот и младите/research-list" >Истражувања</a></li>
            <li class="child" [ngClass]="{'open_color': selectType=='график' && category=='Родот и младите'}">
            <a routerLink="/category/Родот и младите/graph-list" >Графици</a></li>
            <li class="child" [ngClass]="{'open_color': selectType=='инфографик' && category=='Родот и младите'}">
            <a routerLink="/category/Родот и младите/infographic-list">Инфографици</a></li>
          </ul>
        </li>
        <li id="urban_development" (click)="toggleSidebar('urban_development')" #toggles data-id="urban_development">
          <div class="sidebar_img sidebar_img_trans" id="hurb" [ngClass]="{'open_opacity': category=='Родот и јавните простори'}"></div>
          <div class="sidebar_img" id="urb"></div>
          <div class="parent"><div>Урбано планирање</div></div>
          <ul id="child_urban_development" [ngClass]="{'open_sidebar': category=='Родот и јавните простори'|| id=='urban_development' && toggle==true }">
            <li class="child" [ngClass]="{'open_color': selectType=='истражувања' && category=='Родот и јавните простори'}">
            <a routerLink="/category/Родот и јавните простори/research-list" >Истражувања</a></li>
            <li class="child" [ngClass]="{'open_color': selectType=='график' && category=='Родот и јавните простори'}">
            <a routerLink="/category/Родот и јавните простори/graph-list" >Графици</a></li>
            <li class="child" [ngClass]="{'open_color': selectType=='инфографик' && category=='Родот и јавните простори'}">
            <a routerLink="/category/Родот и јавните простори/infographic-list">Инфографици</a></li>
          </ul>
        </li>
         <li id="health" (click)="toggleSidebar('health')" #toggles data-id="health">
          <div class="sidebar_img sidebar_img_trans" id="hheal" [ngClass]="{'open_opacity': category=='Родот и здравјето'}"></div>
          <div class="sidebar_img" id="heal"></div>
          <div class="parent"><div>Здравје</div></div>
          <ul id="child_health" [ngClass]="{'open_sidebar': category=='Родот и здравјето'|| id=='health' && toggle==true }">
            <li class="child" [ngClass]="{'open_color': selectType=='истражувања' && category=='Родот и здравјето'}">
            <a routerLink="/category/Родот и здравјето/research-list" >Истражувања</a></li>
            <li class="child" [ngClass]="{'open_color': selectType=='график' && category=='Родот и здравјето'}">
            <a routerLink="/category/Родот и здравјето/graph-list" >Графици</a></li>
            <li class="child" [ngClass]="{'open_color': selectType=='инфографик' && category=='Родот и здравјето'}">
            <a routerLink="/category/Родот и здравјето/infographic-list">Инфографици</a></li>
          </ul>
        </li>
         <li id="education" (click)="toggleSidebar('education')" #toggles data-id="education">
          <div class="sidebar_img sidebar_img_trans" id="hedu" [ngClass]="{'open_opacity': category=='Родот и образованието'}"></div>
          <div class="sidebar_img" id="edu"></div>
          <div class="parent"><div>Образование</div></div>
          <ul id="child_education" [ngClass]="{'open_sidebar': category=='Родот и образованието'|| id=='education' && toggle==true }">
            <li class="child" [ngClass]="{'open_color': selectType=='истражувања' && category=='Родот и образованието'}">
            <a routerLink="/category/Родот и образованието/research-list" >Истражувања</a></li>
            <li class="child" [ngClass]="{'open_color': selectType=='график' && category=='Родот и образованието'}">
            <a routerLink="/category/Родот и образованието/graph-list" >Графици</a></li>
            <li class="child" [ngClass]="{'open_color': selectType=='инфографик' && category=='Родот и образованието'}">
            <a routerLink="/category/Родот и образованието/infographic-list">Инфографици</a></li>
          </ul>
        </li>
         <li id="wellfare" (click)="toggleSidebar('wellfare')" #toggles data-id="wellfare">
          <div class="sidebar_img sidebar_img_trans" id="hwell" [ngClass]="{'open_opacity': category=='Родот и социјалната политика'}"></div>
          <div class="sidebar_img" id="well"></div>
          <div class="parent"><div style="line-height: 1;">Родот и социјална политика</div></div>
          <ul id="child_wellfare" [ngClass]="{'open_sidebar': category=='Родот и социјалната политика'|| id=='wellfare' && toggle==true }">
           <li class="child" [ngClass]="{'open_color': selectType=='истражувања' && category=='Родот и социјалната политика'}">
            <a routerLink="/category/Родот и социјалната политика/research-list" >Истражувања</a></li>
            <li class="child" [ngClass]="{'open_color': selectType=='график' && category=='Социјална Родот и политиката'}">
            <a routerLink="/category/Родот и социјалната политика/graph-list" >Графици</a></li>
            <li class="child" [ngClass]="{'open_color': selectType=='инфографик' && category=='Родот и социјалната политика'}">
            <a routerLink="/category/Родот и социјалната политика/infographic-list">Инфографици</a></li>
          </ul>
        </li>
         <li id="other" (click)="toggleSidebar('other')" #toggles data-id="other">
          <div class="sidebar_img sidebar_img_trans" id="hoth" [ngClass]="{'open_opacity': category=='Останати графици и истражувања'}"></div>
          <div class="sidebar_img" id="oth"></div>
          <div class="parent"><div>Останати</div></div>
          <ul id="child_other" [ngClass]="{'open_sidebar': category=='Останати графици и истражувања'|| id=='other' && toggle==true }">
             <li class="child" [ngClass]="{'open_color': selectType=='истражувања' && category=='Останати графици и истражувања'}">
            <a routerLink="/category/Останати графици и истражувања/research-list" >Истражувања</a></li>
            <li class="child" [ngClass]="{'open_color': selectType=='график' && category=='Останати графици и истражување'}">
            <a routerLink="/category/Останати графици и истражувања/graph-list" >Графици</a></li>
            <li class="child" [ngClass]="{'open_color': selectType=='инфографик' && category=='Останати графици и истражувања'}">
            <a routerLink="/category/Останати графици и истражувања/infographic-list">Инфографици</a></li>
          </ul>
        </li>
      </ul>
      </div>