import { Component, OnInit } from '@angular/core';
import { ResearchService } from '../../shared/services/research.service';
import { ResearcherService } from '../../shared/services/researcher.service';
import { CategoryService } from '../../shared/services/category.service';
import { map } from 'rxjs/operators';
import {Router, ActivatedRoute} from "@angular/router";

import { Research } from '../../shared/models/research';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { FormBuilder, FormGroup,FormArray, FormControl, Validators } from '@angular/forms';


@Component({
	selector: 'app-dashboard-research-update',
	templateUrl: './dashboard-research-update.component.html',
	styleUrls: ['./dashboard-research-update.component.css']
})
export class DashboardResearchUpdateComponent implements OnInit {
	researchForm!:FormGroup;
	id:any;
	submitted=false;
	research={} as Research;
	fileDataImage:any;
	fileDataPdf:any;
	checkedCat:any;
	checkedRes:any;
	researchData:any;
	image_path:any;
	pdf_path:any;
	new_image:any;
	new_pdf:any;
	uploadImageSuccessful:any;
	uploadPdfSuccessful:any;

	
	constructor(public researchService:ResearchService, private http:HttpClient, private categoryService:CategoryService, private researcherService:ResearcherService, private fb:FormBuilder, private router:Router, private rout:ActivatedRoute) { }

	ngOnInit(): void {
		
		this.id = this.rout.snapshot.paramMap.get('id');
		this.researchForm=this.fb.group({
			title:[''],
			abstract:[''],
			methodology:[''],
			categories:this.fb.array([]),
			researchers:this.fb.array([]),
			year_published:[''],
			date:new Date(),
			image_path:'',
			pdf_path:''
		});
		this.researchData=this.researchService.getResearchData(this.id).valueChanges().subscribe(data=>{
			this.researchForm.patchValue({
				title:data?.title,
				abstract:data?.abstract,
				methodology:data?.methodology,
				categories:data?.categories,
				researchers:data?.researchers,
				year_published:data?.year_published,
				image_path:data?.image_path,
				pdf_path:data?.pdf_path

			});



			this.image_path=this.researchForm.get('image_path')!.value;
			if(this.image_path==undefined){
				this.image_path=""
			}
			this.pdf_path=this.researchForm.get('pdf_path')!.value;		

			if(this.pdf_path){
				this.pdf_path=""
			}	

			data?.categories.forEach((value,key)=>{
				(this.researchForm.get('categories') as FormArray).push(this.fb.group({
					name:value.name,
					ischecked:value.ischecked,
				}))
			});

			data?.researchers.forEach((value:any,key:any)=>{
				(this.researchForm.get('researchers')as FormArray).push(this.fb.group({
					name:value.name,
					ischecked:value.ischecked,
					link:value.link
				}))
			});

			this.researchData.unsubscribe();
		})

	}


	config: AngularEditorConfig = {
		editable: true,
		spellcheck: true,
		height: "200px",
		minHeight: "5rem",
		placeholder: "Enter text here...",
		translate: "no",
		defaultParagraphSeparator: "p",
		defaultFontName: "Arsenal",
		toolbarHiddenButtons: [['insertImage','insertVideo',]],
		sanitize: false,
		customClasses: [
		{
			name: "centerTextImage",
			class: "centerTextImage",
		},
		{	name:"article_img",
		class:"article_img"
	}],
	// uploadUrl: '',
	//    uploadWithCredentials: false,	

};

isResearcherSelected(event:any, name:string){
	var arrayRes=this.researchers();
	if(event.target.checked){
		arrayRes.controls.forEach(function (element:any,index:any) {
			if(element.value.name==name){
				arrayRes.at(index).patchValue({name:element.value.name,ischecked:true,link:element.value.link});
			}
		})
	}else{
		arrayRes.controls.forEach(function (element:any,index:any) {
			if(element.value.name==name){
				arrayRes.at(index).patchValue({name:element.value.name,ischecked:false,link:element.value.link});
			}
		})
	}
}

isCategorySelected(event:any, nameCat:string){

	var arrayCat=this.categories();
	if(event.target.checked){
		arrayCat.controls.forEach(function (element,index) {
			if(element.value.name==nameCat){
				arrayCat.at(index).patchValue({name:element.value.name,description:element.value.description,ischecked:true});
			}
		})
	}else{

		arrayCat.controls.forEach(function (element,index) {
			if(element.value.name==nameCat){
				arrayCat.at(index).patchValue({name:element.value.name,description:element.value.description,ischecked:false});
			}
		})
	}

}

addCategory(){
	window.open('/category-add', 'winname', 'directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no,width=700,height=750');
	let currentUrl = this.router.url;
	this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
		this.router.navigate([currentUrl]);
	});

}
addResearcher(){
	window.open('/researcher-add', 'winname', 'directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no,width=700,height=750');
	let currentUrl = this.router.url;
	this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
		this.router.navigate([currentUrl]);
	});

}
/* File onchange event */
fileEventImage(e:any){
	var file = e.target.files[0];
	var filename=file.name;
	var lastThree=filename.substr(filename.length - 4);
	//var getTitle=this.researchForm.get('title')!.value;
	var getTitle=this.id;

	//getTitle=getTitle.split(' ').join('');
	var researchName="_"+getTitle+lastThree;
	let formData = new FormData();
	formData.append('file', file, researchName);
	this.postImage(formData);

}
postImage(file:any){
	this.http.post('/assets/img/uploads/researches/saveImage.php',file)
	.subscribe(res => {
		//console.log(res);
		//this.image_path=res;
		this.new_image=res;
		this.uploadImageSuccessful=this.new_image.status;
		//this.new_image=res;

		//send success response
	}, (err) => {
		console.log(err)
		//send error response
	});
}

fileEventPdf(e:any){
	var file = e.target.files[0];
	var filename=file.name;
	var lastThree=filename.substr(filename.length - 4);
	//var getTitle=this.researchForm.get('title')!.value;
	//getTitle=getTitle.split(' ').join('');
	var getTitle=this.id;
	var researchName="_"+getTitle+lastThree;
	let formData = new FormData();
	formData.append('file', file, researchName);
	this.postPdf(formData);

}

postPdf(file:any){
	this.http.post('/assets/pdf/uploads/researches/savePdf.php',file)
	.subscribe(res => {
		//this.pdf_path=res;
		this.new_pdf=res;
		this.uploadPdfSuccessful=this.new_pdf.status;

		//send success response
	}, (err) => {
		console.log(err)
		//send error response
	});
}

categories(){
	return this.researchForm.get('categories') as FormArray;
}

researchers(){
	return this.researchForm.get('researchers') as FormArray;
}

deleteResearch(){
	this.researchService.deleteResearch(this.id);
	this.router.navigate(['/research-list']);

}

onSubmit(researchData:any){
	// this.submitted=true;

	if(this.new_image){
			var imgPath=this.new_image.image_path;
			var lastThree=imgPath.substr(imgPath.length - 4)
			// var getTitle=this.researchForm.get('title')!.value;
			// getTitle=getTitle.split(' ').join('');
			var getTitle=this.id;
			var researchName="_"+getTitle+lastThree;
			this.researchForm.get('image_path')!.patchValue('/assets/img/uploads/researches/'+researchName);
		}

		if(this.new_pdf){
			var pdfPath=this.new_pdf.pdf_path;
			var lastThree=pdfPath.substr(pdfPath.length - 4)
			// var getTitle=this.researchForm.get('title')!.value;
			// getTitle=getTitle.split(' ').join('');
			var getTitle=this.id;
			var pdfName="_"+getTitle+lastThree;
			this.researchForm.get('pdf_path')!.patchValue('/assets/pdf/uploads/researches/'+pdfName);
		}
	this.researchService.updateResearch(this.id, researchData.value);
	
		this.router.navigate(['/research-list']);
	

}

}
