import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import {Researcher} from '../models/researcher';
@Injectable({
	providedIn: 'root'
})
export class ResearcherService {
	private dBresearchers='/researchers';
	reserachersRef:AngularFirestoreCollection<Researcher>;
		researchesRefResearch:AngularFirestoreCollection<Researcher>;

	
	
	constructor(private db:AngularFirestore) {
		this.reserachersRef=db.collection(this.dBresearchers,ref => ref.orderBy('name').limit(10));
		this.researchesRefResearch=db.collection(this.dBresearchers,ref => ref.orderBy('name'));

	}

	createResearcher(researcher:Researcher):any{
		return this.reserachersRef.add({...researcher}).then(() => {
			console.log("Document successfully written!");
		})
		.catch((error) => {
			console.error("Error writing document: ", error);
		});
	}

	getAllResearchers(){
		return this.reserachersRef;
	}
	getAllResearchersForResearch(){
		return this.researchesRefResearch
	}

	getResearcherData(id:any){
		return this.reserachersRef.doc(id);
	}
	updateResearcher(id:any, data:any){
		return this.reserachersRef.doc(id).update(data);
	}
	deleteResearcher(id:any){
		return this.reserachersRef.doc(id).delete();
	}
	searchResearcher(title:any):AngularFirestoreCollection<Researcher>{

		const end = title.replace(/.$/, (c:any) => String.fromCharCode(c.charCodeAt(0) + 1));			
		return this.db.collection(this.dBresearchers, ref=>ref.where('name', '>=', title)
			.where('name', '<', end));
	}

	nextResearcherDataOne(next:any):AngularFirestoreCollection<Researcher>{
		return this.db.collection(this.dBresearchers,ref => ref.orderBy('name').startAfter(next['name']).limit(1));
	}

	nextResearcherData(next:any):AngularFirestoreCollection<Researcher>{
		return this.db.collection(this.dBresearchers,ref => ref.orderBy('name').startAfter(next['name']).limit(11));
	}

	prevResearcherData(prev:any):AngularFirestoreCollection<Researcher>{
		return this.db.collection(this.dBresearchers,ref => ref.orderBy('name').endBefore(prev['name']).limitToLast(10));
	}



	getResearcherDataByName(name:any){
		return this.db.collection(this.dBresearchers,ref => ref.where('name','==',name));
	}
}
