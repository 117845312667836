import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import {Graph} from '../models/graph';
@Injectable({
	providedIn: 'root'
})
export class GraphService {
	filterResearch:any;
	filterCategory:any;
	catList:any;
	researchList:any;
	private dBgraph='/graphs';
	graphsRef:AngularFirestoreCollection<Graph>

	constructor(private db:AngularFirestore) {
		this.graphsRef=db.collection(this.dBgraph, ref=>ref.orderBy('date','desc').limit(10)); }

		createGraph(graph:Graph){
			//const document = this.db.collection(this.dBgraph).doc().ref.id;
			

			return this.graphsRef.add({...graph}).then((graphId) => {
				
				console.log("Document successfully written!");
				
			})
			.catch((error) => {
				console.error("Error writing document: ", error);
			});
		}

		getAllGraphs(){
			return this.graphsRef;
		}
		
		getAllGraphsPage(){
			return this.db.collection(this.dBgraph, ref=>ref.limit(10).orderBy('date','desc'));
		}
		getGraphsAll():AngularFirestoreCollection<Graph>{
			return this.db.collection(this.dBgraph, ref=>ref.limit(10).where('graphic_type_view','==','график').orderBy('date','desc'));
		}
		getInfographsAll():AngularFirestoreCollection<Graph>{
			return this.db.collection(this.dBgraph, ref=>ref.limit(10).where('graphic_type_view','==','инфографик').orderBy('date','desc'));
		}
		getGraphData(id:any){
			return this.graphsRef.doc(id);
		}
		updateGraph(id:any, data:any){
			return this.graphsRef.doc(id).update(data);
		}
		deleteGraph(id:any){
			return this.graphsRef.doc(id).delete();
		}
		searchGraphAll(title:any):AngularFirestoreCollection<Graph>{

			const end = title.replace(/.$/, (c:any) => String.fromCharCode(c.charCodeAt(0) + 1));			
			return this.db.collection(this.dBgraph, ref=>ref.where('title', '>=', title)
				.where('title', '<', end));
		}

		searchGraph(title:any):AngularFirestoreCollection<Graph>{

			const end = title.replace(/.$/, (c:any) => String.fromCharCode(c.charCodeAt(0) + 1));			
			return this.db.collection(this.dBgraph, ref=>ref.where('title', '>=', title)
				.where('title', '<', end).where('graphic_type_view','==','график').limit(10));
		}
		searchInfograph(title:any):AngularFirestoreCollection<Graph>{

			const end = title.replace(/.$/, (c:any) => String.fromCharCode(c.charCodeAt(0) + 1));			
			return this.db.collection(this.dBgraph, ref=>ref.where('title', '>=', title)
				.where('title', '<', end).where('graphic_type_view','==','инфографик').limit(10));
		}

//all charts and infographics
		nextGraphAllData(next:any):AngularFirestoreCollection<Graph>{
			return this.db.collection(this.dBgraph,ref => ref.orderBy('date','desc').startAfter(next['date']).limit(11));
		}

		nextGraphAllDataOne(next:any):AngularFirestoreCollection<Graph>{
			return this.db.collection(this.dBgraph,ref => ref.orderBy('date','desc').startAfter(next['date']).limit(1));
		}

		prevGraphAllData(prev:any):AngularFirestoreCollection<Graph>{
			return this.db.collection(this.dBgraph,ref => ref.orderBy('date', 'desc').endBefore(prev['date']).limitToLast(10));
		}


// infographics

nextInfographData(next:any):AngularFirestoreCollection<Graph>{
			return this.db.collection(this.dBgraph,ref => ref.where('graphic_type_view','==','инфографик').orderBy('date','desc').startAfter(next['date']).limit(11));
		}

		nextInfographDataOne(next:any):AngularFirestoreCollection<Graph>{
			return this.db.collection(this.dBgraph,ref => ref.where('graphic_type_view','==','инфографик').orderBy('date','desc').startAfter(next['date']).limit(1));
		}

		prevInfographData(prev:any):AngularFirestoreCollection<Graph>{
			return this.db.collection(this.dBgraph,ref => ref.where('graphic_type_view','==','инфографик').orderBy('date', 'desc').endBefore(prev['date']).limitToLast(10));
		}

// graphs

nextGraphData(next:any):AngularFirestoreCollection<Graph>{
			return this.db.collection(this.dBgraph,ref => ref.where('graphic_type_view','==','график').orderBy('date','desc').startAfter(next['date']).limit(11));
		}

		nextGraphDataOne(next:any):AngularFirestoreCollection<Graph>{
			return this.db.collection(this.dBgraph,ref => ref.where('graphic_type_view','==','график').orderBy('date','desc').startAfter(next['date']).limit(1));
		}

		prevGraphData(prev:any):AngularFirestoreCollection<Graph>{
			return this.db.collection(this.dBgraph,ref => ref.where('graphic_type_view','==','график').orderBy('date', 'desc').endBefore(prev['date']).limitToLast(10));
		}



		//filter graphs

		async filterCategoryList(id:any){
			this.filterCategory=this.db.collection(this.dBgraph,ref => ref.where("categories", "array-contains",  { ischecked:true, name : id}).where('graphic_type_view','==','график').orderBy('date','desc').limit(10));
			return await new Promise((resolve, reject) => {
				let filterCatListListener=this.filterCategory.snapshotChanges().subscribe((data:any) => {
					this.catList = data.map((e:any) => {
						return {
							...{id:e.payload.doc.id, data:e.payload.doc.data()}
						};
					});
					resolve(this.catList);
					filterCatListListener.unsubscribe();
				});
			});
		}



		


		nextCatGraphData(id:any,last:any):AngularFirestoreCollection<Graph>{
			return this.db.collection(this.dBgraph,ref => ref.where("categories", "array-contains",  { ischecked:true, name : id}).where('graphic_type_view','==','график').orderBy('date','desc').startAfter(last['date']).limit(11));
		}

		nextCatGraphDataOne(id:any,last:any):AngularFirestoreCollection<Graph>{
			return this.db.collection(this.dBgraph,ref => ref.
			where("categories", "array-contains",  { ischecked:true, name : id}).
			where('graphic_type_view','==','график').
			orderBy('date','desc').
			startAfter(last['date']).
			limit(1));
		}

		prevCatGraphData(id:any,first:any):AngularFirestoreCollection<Graph>{
			return this.db.collection(this.dBgraph,ref => ref.
				where("categories", "array-contains",  { ischecked:true, name : id}).
				where('graphic_type_view','==','график').
				orderBy('date','desc').
				endBefore(first['date']).
				limitToLast(10));
		}


		// filter infographics
		async filterCategoryListInfographic(id:any){
			this.filterCategory=this.db.collection(this.dBgraph,ref => ref.where("categories", "array-contains",  { ischecked:true, name : id}).where('graphic_type_view','==','инфографик').orderBy('date','desc').limit(10));
			return await new Promise((resolve, reject) => {
				let filterCatListListener=this.filterCategory.snapshotChanges().subscribe((data:any) => {
					this.catList = data.map((e:any) => {
						return {
							...{id:e.payload.doc.id, data:e.payload.doc.data()}
						};
					});
					resolve(this.catList);
					filterCatListListener.unsubscribe();
				});
			});
		}


		nextCatInfographData(id:any,next:any):AngularFirestoreCollection<Graph>{
			return this.db.collection(this.dBgraph,ref => ref.where("categories", "array-contains",  { ischecked:true, name : id}).where('graphic_type_view','==','инфографик').orderBy('date','desc').startAfter(next['date']).limit(11));
		}

		nextCatInfographDataOne(id:any,last:any):AngularFirestoreCollection<Graph>{
			return this.db.collection(this.dBgraph,ref => ref.
			where("categories", "array-contains",  { ischecked:true, name : id}).
			where('graphic_type_view','==','инфографик').
			orderBy('date','desc').
			startAfter(last['date']).
			limit(1));
		}

		prevCatInfographData(id:any,first:any):AngularFirestoreCollection<Graph>{
			return this.db.collection(this.dBgraph,ref => ref.
				where("categories", "array-contains",  { ischecked:true, name : id}).
				where('graphic_type_view','==','инфографик').
				orderBy('date','desc').
				endBefore(first['date']).
				limitToLast(10));
		}


		

		async getGraphDataByResearchName(name:any){
			this.filterResearch=this.db.collection(this.dBgraph,ref => ref.where("researches", "array-contains",  { ischecked:true, name : name}).orderBy('date','desc'));
			return await new Promise((resolve, reject) => {
				let filterResearchListListener=this.filterResearch.snapshotChanges().subscribe((data:any) => {
					this.researchList = data.map((e:any) => {
						return {
							...{id:e.payload.doc.id, data:e.payload.doc.data()}
						};
					});
					resolve(this.researchList);
					filterResearchListListener.unsubscribe();
				});
			});
		}
		getGraphDataByName(name:any){
			//__name__ gets the docs 
			return this.db.collection(this.dBgraph,ref => ref.where('__name__', '==', name));
		}
	}
