import { Component, OnInit } from '@angular/core';
import { ResearchService } from '../../shared/services/research.service';
import { ResearcherService } from '../../shared/services/researcher.service';
import { CategoryService } from '../../shared/services/category.service';
import { map } from 'rxjs/operators';
import {Router, ActivatedRoute} from "@angular/router";

import { Research } from '../../shared/models/research';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { FormBuilder, FormGroup,FormArray, FormControl, Validators } from '@angular/forms';


@Component({
	selector: 'app-dashboard-research-add',
	templateUrl: './dashboard-research-add.component.html',
	styleUrls: ['./dashboard-research-add.component.css']
})
export class DashboardResearchAddComponent implements OnInit {
	htmlContent = '';
	htmlContentWithoutStyles='';
	researchForm!:FormGroup;
	submitted=false;
	research={} as Research;
	fileDataImage:any;
	fileDataPdf:any;
	categories:any;
	researchers:any;
	checkedCat:any;
	checkedRes:any;
	filedataImage:any;
	filedataPdf:any;

	image_path:any;
	new_image:any;
	pdf_path:any;
	new_pdf:any;
uploadImageSuccessful:any;
uploadPdfSuccessful:any;

	constructor(public researchService:ResearchService, private http:HttpClient, private categoryService:CategoryService, private researcherService:ResearcherService, private fb:FormBuilder, private router:Router) { }
	ngOnInit(): void {
		this.researchForm=this.fb.group({
			title:['', Validators.required],
			abstract:['', Validators.required],
			methodology:['', Validators.required],
			categories:this.fb.array([]),
			researchers:this.fb.array([]),
			year_published:['', Validators.required],
			image_path:'',
			pdf_path:'',
			date:new Date()
		})
		this.getAllCategories();
		this.getAllResearchers();

	}

	config: AngularEditorConfig = {
		editable: true,
		spellcheck: true,
		height: "200px",
		minHeight: "5rem",
		placeholder: "Enter text here...",
		translate: "no",
		defaultParagraphSeparator: "div",
		defaultFontName: "PT Sans",
		toolbarHiddenButtons: [['insertImage','insertVideo',]],
		sanitize: false,
		customClasses: [
		{
			name: "centerTextImage",
			class: "centerTextImage",
		},
		{	name:"article_img",
		class:"article_img"
	}],
	// uploadUrl: '',
	//    uploadWithCredentials: false,	
};


getAllResearchers(){
	console.log('blaw')
	let listener = this.researcherService.getAllResearchersForResearch().snapshotChanges().pipe(
		map(actions => actions.map(a => {
			const data = a.payload.doc.data();
			const id = a.payload.doc.id;
			return { ...data };
			
		}))).subscribe(data=>{
		for(let res of data){

			(this.researchForm.get('researchers') as FormArray).push(new FormGroup({
				'name' : new FormControl(res.name),
				//'link': new FormControl(res.link),
				ischecked: new FormControl(false)
			}))
		}
		this.researchers=data;
		listener.unsubscribe();
	});
	}

	getAllCategories(){
		let listener = this.categoryService.getAllCategories().snapshotChanges().pipe(
			map(actions => actions.map(a => {
				const data = a.payload.doc.data();
				const id = a.payload.doc.id;
				return { ...data };
			}))).subscribe(data=>{
			for(let cat of data){
				
				(this.researchForm.get('categories') as FormArray).push(new FormGroup({
					'name' : new FormControl(cat.name),
					ischecked: new FormControl(false)
				}))
			}

			this.categories=data;
			listener.unsubscribe();
		});

		}

		isCategorySelected(event:any, nameCat:string){
			this.checkedCat=false;
			var arrayCat=this.researchForm.get('categories') as FormArray;

			if(event.target.checked){

				arrayCat.controls.forEach(function (element,index) {
					if(element.value.name==nameCat){
						arrayCat.at(index).patchValue({name:element.value.name,ischecked:true});
					}
				})
			}else{

				arrayCat.controls.forEach(function (element,index) {
					if(element.value.name==nameCat){
						arrayCat.at(index).patchValue({name:element.value.name,ischecked:false});
					}
				})
			}

			//for validators
			for(let checked of arrayCat.controls){
				if(checked.value.ischecked==true){
					this.checkedCat=true;
				}
			}
		}

		isResearcherSelected(event:any, nameRes:any){
			this.checkedRes=false;
			var arrayRes=this.researchForm.get('researchers') as FormArray;

			if(event.target.checked){

				arrayRes.controls.forEach(function (element,index) {
					if(element.value.name==nameRes){
						arrayRes.at(index).patchValue({name:element.value.name, link:element.value.link, ischecked:true});
					}
				})
			}else{

				arrayRes.controls.forEach(function (element,index) {
					if(element.value.name==nameRes){
						arrayRes.at(index).patchValue({name:element.value.name, link:element.value.link, ischecked:false});
					}
				})
			}

			//for validators
			for(let checked of arrayRes.controls){
				if(checked.value.ischecked==true){
					this.checkedRes=true;
				}

			}
		}

		addCategory(){
			window.open('/category-add', 'winname', 'directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no,width=700,height=750');
			let currentUrl = this.router.url;
			this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
				this.router.navigate([currentUrl]);
			});

		}
		addResearcher(){
			window.open('/researcher-add', 'winname', 'directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no,width=700,height=750');
			let currentUrl = this.router.url;
			this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
				this.router.navigate([currentUrl]);
			});

		}



		/* File onchange event */
		fileEventImage(e:any){
			var file = e.target.files[0];
			var filename=file.name;
			var lastThree=filename.substr(filename.length - 4);
			var getTitle=this.researchForm.get('title')!.value;
			getTitle=getTitle.split(' ').join('');
			var researchName="_"+getTitle+lastThree;
			let formData = new FormData();
			formData.append('file', file, researchName);
			this.postImage(formData);


		}
		postImage(file:any){
			this.http.post('/assets/img/uploads/researches/saveImage.php',file)
			.subscribe(res => {
				//console.log(res);
				
				this.new_image=res;
				this.uploadImageSuccessful=this.new_image.status;

				
				//send success response
			}, (err) => {
				console.log(err)
				//send error response
			});
		}

		fileEventPdf(e:any){
			var file = e.target.files[0];
			var filename=file.name;
			var lastThree=filename.substr(filename.length - 4);
			var getTitle=this.researchForm.get('title')!.value;
			getTitle=getTitle.split(' ').join('');
			var researchName="_"+getTitle+lastThree;
			let formData = new FormData();
			formData.append('file', file, researchName);
			this.postPdf(formData);

		}

		postPdf(file:any){
			this.http.post('https://rodovrector.mk/assets/pdf/uploads/researches/savePdf.php',file)
				.subscribe(res => {
					console.log(res);
					this.pdf_path=res;
					this.new_pdf=res;
					this.uploadPdfSuccessful=this.new_pdf.status;

					//send success response
				}, (err) => {
					console.log(err)
					//send error response
				});
		}



		onSubmit(researchData:any){
			this.submitted=true;
			if(researchData.valid){


				if(this.new_image){
					var imgPath=this.new_image.image_path;
					var lastThree=imgPath.substr(imgPath.length - 4)
					var getTitle=this.researchForm.get('title')!.value;
					getTitle=getTitle.split(' ').join('');
					var researchName="_"+getTitle+lastThree;

					this.researchForm.get('image_path')!.patchValue('/assets/img/uploads/researches/'+researchName);
				}

			

				if(this.new_pdf){
					var pdfPath=this.new_pdf.pdf_path;
					var lastThree=pdfPath.substr(pdfPath.length - 4)
					var getTitle=this.researchForm.get('title')!.value;
					getTitle=getTitle.split(' ').join('');
					var pdfName="_"+getTitle+lastThree;

					this.researchForm.get('pdf_path')!.patchValue('/assets/pdf/uploads/researches/'+pdfName);
				}

				this.researchService.createResearch(researchData.value);
				this.router.navigate(['research-list']);
			}

		}

	}
